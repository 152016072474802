
















































@use "sass:math";
@import '@design';
@import '@styleMixins';

.toolbar .theme--light.v-toolbar {
  z-index: 3;
  &,
  .v-toolbar__content {
    > * {
      z-index: 1;
    }
  }
  .v-toolbar__content {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: 414px) {
      padding: 0 5px;
    }
  }
}

.nav-left {
  flex: 0 1 auto;
}

.nav-route a,
.nav-menu-cta .v-btn__content {
  @extend .poppins--font;
  font-weight: bold;
  color: $color-bubs-charcoal;
}

.nav-menu-cta {
  @include padding-lr(1.5em);

  .v-btn__content {
    font-size: 1.1rem;
  }
}

.nav-route {
  display: inline-block;
  margin: 0 math.div($size-grid-padding, 2);
  text-decoration: none;
  text-transform: uppercase;
  a {
    color: white;
    text-decoration: none;
    letter-spacing: 0.074em !important;
  }
  &.BaseButton a {
    color: $color-button-text;
  }
  &.social-icon {
    flex: 0 1 auto;
    img {
      transition: opacity 0.2s ease;
    }
    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
  &.router-link-active a {
    padding-bottom: 0.4em;
    color: white;
    text-decoration: none;
    cursor: default;
    border-bottom: 2px solid white;
  }
} // nav-route
